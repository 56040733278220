import React from 'react';
import Layout from '../../components/layout/Layout';
import dossier from '../../data/pratiche-patenti/duplicato-riclassificazione/dossier.json';
import ContentDossier from '../../components/ContentDossiers';
import PageNavigator from '../../components/layout/PageNavigator';
import ContentDossierPrice from '../../components/ContentDossierPrice';
import Seo from '../../components/layout/SEO';

const DuplicatoRiclassificazione = () => {
  return (
    <Layout>
      <Seo
        title={"Duplicato per Declassamento | Autoscuola Faro, Pisa"}
        description={"Ottieni il duplicato della patente per declassamento o riclassificazione se cambia la categoria della tua patente."}
        keywords={[
          "Duplicato Patente Declassamento Pisa",
          "Duplicato Patente Riclassificazione Pisa",
          "Richiesta Duplicato Patente Pisa"
        ]}
      />

      <PageNavigator link="/pratiche-patenti" text="Pratiche patenti" />
      <ContentDossierPrice dossier={dossier} />
    </Layout>
  );
};

export default DuplicatoRiclassificazione;
